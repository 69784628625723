import { type SVGProps } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { type IconName } from '@/icon-name'
import href from './icons/sprite.svg'

export { href, IconName }

const sizeClassName = {
	'0': 'w-[1em] h-[1em]',
	'1': 'w-3 h-3',
	'2': 'w-4 h-4',
	'3': 'w-5 h-5',
	'4': 'w-6 h-6',
	'5': 'w-7 h-7',
} as const

type Size = keyof typeof sizeClassName

const childrenSizeClassName: Record<Size, string> = {
	'0': 'gap-1.5',
	'1': 'gap-1.0',
	'2': 'gap-1.5',
	'3': 'gap-2',
	'4': 'gap-2',
	'5': 'gap-3',
}

interface IconProps extends SVGProps<SVGSVGElement> {
	name: IconName
	size?: Size
	side?: 'left' | 'right'
}

/**
 * Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you can pass the text as a child of
 * the icon and it will be automatically aligned.
 * Alternatively, if you're not ok with the icon being to the left of the text,
 * you need to wrap the icon and text in a common parent and set the parent to
 * display "flex" (or "inline-flex") with "items-center" and a reasonable gap.
 */
export function Icon({
	name,
	size = '0',
	className,
	children,
	side = 'left',
	...props
}: IconProps) {
	const iconElement = (
		<svg
			{...props}
			className={cn(sizeClassName[size], 'inline self-center', className)}
		>
			<use href={`${href}#${name}`} />
		</svg>
	)

	if (!children) {
		return iconElement
	}

	return (
		<span className={`inline-flex items-center ${childrenSizeClassName[size]}`}>
			{side === 'left' ? (
				<>
					{iconElement}
					{children}
				</>
			) : (
				<>
					{children}
					{iconElement}
				</>
			)}
		</span>
	)
}
